import React from 'react';
import styles from './Blob.module.css';
import { useEffect, useRef, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'
import {BrowserView, MobileView} from 'react-device-detect';

type BlobProp = {
  srcMP4 : string,
  srcWEBM : string,
  picture : string,
};

const Blob: React.FC<BlobProp> = ( {srcMP4, srcWEBM, picture}) => {
  
  const [inViewRef, inView] = useInView({
    threshold: 0.2,
  })

  const videoRef = useRef<HTMLVideoElement | null>(null)

  const setRefs = useCallback(
    (node: HTMLVideoElement) => {
      videoRef.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  useEffect(() => {
    if (!videoRef || !videoRef.current) {
      return
    }

    if (inView) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }, [inView])

  return (
    <div className={styles.blob}>
      <BrowserView>
        <video ref={setRefs} className={styles.background} autoPlay loop muted>
          <source src={srcMP4} type="video/mp4;codecs=hvc1" />
          <source src={srcWEBM} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </BrowserView>
      <MobileView>
        <img src={picture} alt="blob" className={styles.background} />
      </MobileView>
    </div>
  )
}

export default Blob;