import React from 'react';
import styles from './Header.module.css';

const Header: React.FC = () => {
    return (
      <header className={styles.header}>
        <div className={styles.buttons_sections}>
          <a href='#aboutme'>About me</a>
          <a href='#projects'>Projects</a>
          <a href='#contact'>Contact</a>
        </div>
      </header>
    )
  }

export default Header;