import React from 'react';
import styles from './HelpYou.module.css';
import Help from '../../Help/Help';
import { gsap } from 'gsap';
import { useRef, useEffect } from 'react';

const HelpYou: React.FC = () => {

  const title = useRef(null);
  const paragraph = useRef(null);
  const container = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from(
        title.current,
        {
          opacity: 0.5,
          duration: 1.5,
          y: "131%",
          ease: "power4.out",
          delay: .5,
          skewY: 5,
          stagger: {
            amount: 0.3
          },
          scrollTrigger: {
            trigger: title.current,
            start: 'top bottom',
            end: 'top bottom',   
          }, 
        }
      );

      gsap.to(
        container.current,
        {
          backgroundColor: '#272727',
          duration: 1.5, 
          scrollTrigger: {
            trigger: container.current,
            start: '100px bottom',
            end: '100px bottom',   
          }, 
        }
      );
    }, container);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={container} className={styles.helpyou}>
      <div className={styles.title}>
        <h3 ref={title}>My Skills</h3>
      </div>
      <div className={styles.helps}>
        <Help 
        title='Front-End Development'
        paragrah="Whether it's designing responsive layouts, optimising performance, implementing dynamic features or integrating mock-ups, I use my knowledge and creativity to deliver engaging user experiences. I also keep abreast of the latest trends and best practices in front-end development, enabling me to incorporate cutting-edge techniques and technologies into my projects."
        chips={['React', 'Next.js', 'Wordpress', 'GSAP', 'TypeScript', 'Figma', 'JQuery', 'HTML', 'CSS']}></Help>
        <Help
        title='Back-End Development'
        paragrah="My experience has given me a solid understanding of server-side programming and database management. From designing and implementing RESTful APIs, configuring a socket server, integrating a headless CMS such as Strapi or Payload.CMS, optimising database queries or deploying a website, I make sure that my backend solutions are reliable, high-performing and scalable."
        chips={['Node.js', 'AWS', 'MongoDB', 'Laravel', 'PHP', 'Express.js', 'Docker', 'MySQL', 'Strapi']}></Help>
        <Help
        title='Project Management'
        paragrah="With my 5 years of study in the field, I've learnt how to coordinate tasks, resources and schedules to ensure that projects are completed on time and on budget. For me, a good project manager needs to know his subject by heart and that's why I want to further improve my development skills."
        chips={['Kanban', 'Modelization', 'Agile', 'Quality', 'DevOps']}></Help>

      </div>
    </div>
  )
}

export default HelpYou;