import React from 'react';
import styles from './Contact.module.css';
import Blob from '../../Blob/Blob';
import { gsap } from 'gsap';
import { useRef, useEffect } from 'react';
import blobVideoMP4 from '../../../assets/videos/blob-gradient-invert.mov';
import blobVideoWEBM from '../../../assets/videos/blob-gradient-invert.webm';
import blobPicture from '../../../assets/img/blob-gradient-invert.webp';
import cursorStyles from '../../Cursor/Cursor.module.css';

const Contact: React.FC = () => {

  const title = useRef<HTMLInputElement>(null);;
  const container = useRef<HTMLInputElement>(null);;

  useEffect(() => {
    let ctx = gsap.context(() => {
      // console.log(title.current?.getBoundingClientRect());
      // let cursor = (document.getElementById(`${cursorStyles.cursor}`))
      // if (title.current == null) return;
      // gsap.to( cursor,{
      //     scrollTrigger: {
      //       trigger: title.current,
      //       toggleActions: "restart pause reverse reverse",
      //       markers: true,
      //     },
      //           /* Set cursor to the title x and y position */
      //     x: title.current.getBoundingClientRect().top,
      //     y: title.current.getBoundingClientRect().y,
      //     width: "300px",
      //     height: "300px",
      //     borderRadius: 0,
      //     ease: "power3",
      // });  
    
      

      gsap.from(
        title.current,
        {
          opacity: 0.5,
          duration: 1.5,
          y: "100%",
          ease: "power4.out",
          delay: 0.2,
          skewY: 5,
          stagger: {
            amount: 0.3
          },
          scrollTrigger: {
            trigger: title.current,
            start: 'top bottom',
            end: 'top bottom',   
          }, 
        }
      );

      gsap.from(
        `.${styles.line},.${styles.line_desktop},.${styles.line_mobile}`,
        {
          opacity: 0.5,
          duration: 4,
          width: 0,
          ease: "power4.out",
          delay: 0.2,
          scrollTrigger: {
            trigger: `.${styles.infos_title}`,
            start: 'top bottom',
            end: 'top bottom',   
          }, 
        }
      );

      gsap.from(
        `.${styles.infos_title}`,
        {
          opacity: 0.5,
          duration: 1.5,
          y: "110%",
          ease: "power4.out",
          delay: .5,
          skewY: 3,
          stagger: {
            amount: 0.3
          },
          scrollTrigger: {
            trigger: `.${styles.infos_title}`,
            start: 'top bottom', 
            end: 'top bottom',   
          }, 
        }
      );

      gsap.from(
        `.${styles.social},.${styles.detail},.${styles.address}`,
        {
          opacity: 0.5,
          duration: 1.3,
          y: "130%",
          ease: "power4.out",
          delay: .7,
          skewY: 3,
          stagger: {
            amount: 0.3
          },
          scrollTrigger: {
            trigger: `.${styles.infos_title}`,
            start: 'top bottom', 
            end: 'top bottom',   
          }, 
        }
      );
    }, container);

    return () => ctx.revert();  

  }, []);

  return (
    <div id="contact" ref={container} className={styles.contact}>
      <div className={styles.blob}>
        <Blob
        srcMP4={blobVideoMP4}
        srcWEBM={blobVideoWEBM}
        picture={blobPicture}></Blob>
      </div>
      <div className={styles.title}>
        <h3 ref={title}>Get in touch</h3> {process.env.REACT_APP_NODE_ENV}
      </div>
      <div className={styles.infos}>
        <div className={styles.socials}>
          <div className={styles.infos_title_container}>
            <h4 className={styles.infos_title}>Social networks</h4>
          </div>
          <div className={styles.social_container}>
            <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_INSTAGRAM_LINK} className={styles.social}>Instagram</a>
            <div className={styles.line}></div>
          </div>
          <div className={styles.social_container}>
            <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_TWITTER_LINK} className={styles.social}>X</a>
            <div className={styles.line}></div>
          </div>
          <div className={styles.social_container}>
            <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_LINKEDIN_LINK} className={styles.social}>LinkedIn</a>
            <div className={styles.line}></div>
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.infos_title_container}>
            <h4 className={styles.infos_title}>Details</h4>
          </div>
          <div className={styles.detail_container}>
            <div className={styles.line_desktop}></div>
            <a href={"mailto:" + process.env.REACT_APP_EMAIL} className={styles.detail}>{process.env.REACT_APP_EMAIL}</a>
            <div className={styles.line}></div>
          </div>
          <div className={styles.detail_container}>
            <div className={styles.line_desktop}></div>
            <a href={"tel:" + process.env.REACT_APP_PHONE} className={styles.detail}>{process.env.REACT_APP_PHONE}</a>
            <div className={styles.line}></div>
          </div>
          <div className={styles.detail_container}>
            <div className={styles.line}></div>
          </div>
        </div>
        <div className={styles.address_infos}>
        <div className={styles.infos_title_container}>
            <h4 className={styles.infos_title}>Address</h4>
          </div>
          <div className={styles.address_container}>
            <div className={styles.line_desktop}></div>
            <a target="_blank" href="https://www.google.com/maps/place/Spoorlaan+398,+5038+CG+Tilburg/" rel="noopener noreferrer" className={styles.address}>Spoorlaan 398-01</a>
            <div className={styles.line_mobile}></div>
          </div>
          <div className={styles.address_container}>
            <div className={styles.line_desktop}></div>
            <a target="_blank" href="https://www.google.com/maps/place/Spoorlaan+398,+5038+CG+Tilburg/" rel="noopener noreferrer" className={styles.address}>5038 Tilburg</a>
            <div className={styles.line_mobile}></div>
          </div>
          <div className={styles.address_container}>
            <div className={styles.line_desktop}></div>
            <a target="_blank" href="https://www.google.com/maps/place/Spoorlaan+398,+5038+CG+Tilburg/" rel="noopener noreferrer" className={styles.address}>Netherlands</a>
            <div className={styles.line_mobile}></div>
          </div>
        </div>  
      </div>
    </div>
  )
}

export default Contact;