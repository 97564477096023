import React from 'react';
import styles from './Project.module.css';
import { gsap } from 'gsap';
import { useRef, useEffect } from 'react';
import cursorStyles from '../Cursor/Cursor.module.css';

type ProjectProp = {
  title : string,
  subtitle : string,
  img : string,
  url: string,
  date: string,
};

const Project: React.FC<ProjectProp> = ({title, subtitle, img, url, date}) => {

  const dateRef = useRef(null);
  const text = useRef(null);
  const container = useRef<any>();
  const line = useRef(null);
  const target = (url == "") ? "_self" : "_blank";

  const checkRedirect = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (url == "") {
      e.preventDefault();
    }
  }
  
  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from(
        [text.current, dateRef.current],
        {
          opacity: 0.5,
          duration: 1.5,
          y: "110%",
          ease: "power4.out",
          delay: 0.2,
          skewY: 3,
          stagger: {
            amount: 0.3
          },
          scrollTrigger: {
            trigger: text.current,
            start: 'top 95%', 
            end: 'top 95%',   
          }, 
        }
      );

      gsap.from(
        line.current,
        {
          opacity: 0.5,
          duration: 1.5,
          width: 0,
          ease: "power4.out",
          delay: 0.4,
          stagger: {
            amount: 0.3
          },
          scrollTrigger: {
            trigger: text.current,
            start: 'top 95%', 
            end: 'top 95%',   
          }, 
        }
      );
    }, container);
    return () => ctx.revert();

  }, []);

  const setImageToCursor = (e: React.MouseEvent<HTMLAnchorElement>) => {
    gsap.to(`#${cursorStyles.cursor}`, {
      backgroundImage: `url(${img})`,
      backgroundColor: "transparent",
      duration: 0,
    });
    gsap.to(`#${cursorStyles.cursor}`, {
      width: "300px",
      height: "300px",
      borderRadius: 0,
      mixBlendMode: "unset",
      ease: "power3",
    });  }

  const resetCursor = (e: React.MouseEvent<HTMLAnchorElement>) => {
    gsap.to(`#${cursorStyles.cursor}`, {
      backgroundImage: "none",
      duration: 0,
      backgroundColor: "var(--primary-color-alt)",

    });
    gsap.to(`#${cursorStyles.cursor}`, {
      width: "15px",
      height: "15px",
      borderRadius: "50%",
      mixBlendMode: "difference",
      ease: "power3",
    });
  }

    return (
      <a href={url} target={target} onClick={checkRedirect} rel="noopener noreferrer" ref={container} onMouseEnter={setImageToCursor} onMouseLeave={resetCursor} className={styles.project}>
        <div className={styles.text_container}>
          <p ref={dateRef} className={styles.date}>{date}</p>
          <div ref={text} className={styles.text}>
            <h4>{title}</h4>
            <p>-</p>
            <p>{subtitle}</p>
          </div>
        </div>
        <div ref={line} className={styles.line}></div>
      </a>
    )
  }

export default Project;