import React from 'react';
import styles from './Hello.module.css';
import Blob from '../../Blob/Blob'
import { gsap } from 'gsap';
import { useRef, useEffect } from 'react';
import blobVideoMP4 from '../../../assets/videos/blob-gradient.mov';
import blobVideoWEBP from '../../../assets/videos/blob-gradient.webm';
import blobPicture from '../../../assets/img/blob-gradient.webp';

const Hello: React.FC = () => {
  const title = useRef(null);
  const subtitle = useRef(null);
  const container = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {

      gsap.from(
        title.current,
        {
          opacity: 0.5,
          duration: 1.5,
          y: "170%",
          ease: "power4.out",
          delay: 0.2,
          skewY: 5,
          stagger: {
            amount: 0.3
          },
          scrollTrigger: {
            trigger: container.current,
          }, 
        }
      );

      gsap.from(
        subtitle.current,
        {
          opacity: 0.5,
          duration: 1.3,
          y: "130%",
          ease: "power4.out",
          delay: .4,
          skewY: 4,
          stagger: {
            amount: 0.3
          },
          scrollTrigger: {
            trigger: container.current,
          }, 
        }
      );

    }, container);

    return () => ctx.revert();
  }, []);

  return (
    <div className={styles.hello} ref={container}>
      <div className={styles.blob}>
          <Blob 
          srcMP4={blobVideoMP4}
          srcWEBM={blobVideoWEBP}
          picture={blobPicture}></Blob>
      </div>
      <span className={styles.presentation}>
        <div className={styles.title}>
          <h1 ref={title} >GAUTHIER NELKIN</h1>
        </div>
        <div className={styles.subtitle}>
          <h2 ref={subtitle}>Full Stack Developer</h2>
        </div>
      </span>
    </div>
  )
}

export default Hello;