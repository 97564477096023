import React from 'react';
import styles from './Cursor.module.css';
import { gsap } from 'gsap';
import { useRef, useEffect } from 'react';
import { Component } from 'react';

const Cursor: React.FC = () => {
    const cursor = useRef<any>();


    useEffect(() => {
        let isMobile = (window.matchMedia("(pointer: coarse)").matches);
        if (isMobile) return;

        let xTo = gsap.quickTo(cursor.current, "x", {duration: 0.4, ease: "power3"});
        let yTo = gsap.quickTo(cursor.current, "y", {duration: 0.4, ease: "power3"});
        let setX = gsap.quickSetter(cursor.current, "x", "px");
        let setY = gsap.quickSetter(cursor.current, "y", "px");
        gsap.set(cursor.current, {xPercent: -50, yPercent: -50});

        document.documentElement.addEventListener('mouseleave', () => {
            if (cursor.current == null) return;
            cursor.current.style.opacity = "0";
        })
        document.documentElement.addEventListener('mouseenter', (e) => {
            if (cursor.current == null) return;
            setX(e.clientX);
            setY(e.clientY);
            cursor.current.style.opacity = "1";
        })

        window.addEventListener("mousemove", e => {
            if (cursor.current == null) return;
            
            if (cursor.current.style.opacity = "0")
                cursor.current.style.opacity = "1";

            xTo(e.clientX);
            yTo(e.clientY);
        });
        
        "a:not(#projects *), button, .hoverable".split(", ").forEach(selector => {
            document.querySelectorAll(selector).forEach(el => {
                el.addEventListener("mouseenter", () => {
                    if (cursor.current == null) return;
                    gsap.to(cursor.current, {scale: 1.7, ease: "power3"});
                });
                el.addEventListener("mouseleave", () => {
                    if (cursor.current == null) return;
                    gsap.to(cursor.current, {scale: 1, ease: "power3"});
                });
            });
        });
    }, []);

    return (
        <div ref={cursor} id={styles.cursor} className={styles.cursor}>
        </div>
    )
}

export default Cursor;