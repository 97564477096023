import React from 'react';
import styles from './Projects.module.css';
import Project from '../../Project/Project';
import { gsap } from 'gsap';
import { useRef, useEffect } from 'react';
import JadeRoller from '../../../assets/img/jaderoller.gif';
import Studeat from '../../../assets/img/studeat.gif';
import TheoNothias from '../../../assets/img/theonothias.gif'
import Drmesi from '../../../assets/img/drmesi.gif';
import VHC from '../../../assets/img/vhc.gif';
const Projects: React.FC = () => {
  
  const title = useRef(null);
  const container = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from(
        title.current,
        {
          opacity: 0.5,
          duration: 1.5,
          y: "120%",
          ease: "power4.out",
          delay: 0.2,
          skewY: 5,
          stagger: {
            amount: 0.3
          },
          scrollTrigger: {
            trigger: title.current,
            start: 'top bottom',
            end: 'top bottom',   
          }, 
        }
      );
    }, container);

    return () => ctx.revert();
  }, []);

  return (
    <div id="projects" ref={container} className={styles.projects}>
      <div className={styles.title}>
        <h3 ref={title}>My projects</h3>
      </div>
      <div className={styles.projects_list}>
        <Project
        title='Theo Nothias'
        subtitle='Next.JS - Node.JS - MongoDB'
        url='https://theonothias.com'
        date='2024'
        img={TheoNothias}
        />
        <Project
        title='Dr Mesi'
        subtitle='React.JS - Laravel - AWS'
        url='https://drmesi.com'
        date='2024'
        img={Drmesi}
        />
        <Project
        title='Vision Healthcare'
        subtitle='Next.JS - Hygraph'
        url='https://visionhealthcare.eu'
        date='2024'
        img={VHC}
        />
        <Project 
        title='Jade Roller'
        subtitle='Wordpress - PHP - Javascript'
        img={JadeRoller}
        url='https://jaderoller.com'
        date='2023'
        />
        <Project 
        title='Studeat'
        subtitle='Ionic - Firebase'
        img={Studeat}
        url=''
        date='2022'
        />
      </div>
    </div>
  )
}

export default Projects;