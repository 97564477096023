import React from 'react';
import styles from './Help.module.css';
import { gsap } from 'gsap';
import { useRef, useEffect } from 'react';

type HelpProp = {
  title : string,
  paragrah : string,
  chips : string[],
};


const Help: React.FC<HelpProp> = ({title, paragrah, chips}) => {

  const titleRef = useRef(null);
  const paragraphRef = useRef(null);
  const container = useRef(null);
  const chipsRef = useRef(null);
  const contact = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from(
        titleRef.current,
        {
          opacity: 0.5,
          duration: 1.5,
          y: "800%",
          ease: "power4.out",
          delay: 0.2,
          skewY: 5,
          stagger: {
            amount: 0.3
          },
          scrollTrigger: {
            trigger: container.current,
            start: 'top bottom',
            end: 'top bottom',   
          }, 
        }
      );

      gsap.from(
        paragraphRef.current,
        {
          opacity: 0,
          duration: 1.3,
          y: "30%",
          ease: "power4.out",
          delay: 1.2,
          skewY: .8,
          stagger: { 
            amount: 0.3
          },
          scrollTrigger: {
            trigger: container.current,
            end: 'top bottom',          
            start: 'top bottom',
          }, 
        }
      );

      gsap.from(
        chipsRef.current,
        {
          opacity: 0,
          duration: 1.3,
          y: "30%", 
          ease: "power4.out",
          delay: 1.6,
          skewY: .8,
          stagger: { 
            amount: 0.3
          },
          scrollTrigger: {
            trigger: container.current,
            end: 'top bottom',          
            start: 'top bottom',
          }, 
        }
      );


      gsap.from(
        contact.current,
        {
          opacity: 0,
          duration: 2.5,
          ease: "power4.out",
          delay: 2,
          scrollTrigger: {
            trigger: container.current,
            end: 'top bottom',          
            start: 'top bottom',
          }, 
        }
      );
    }, container);

    return () => ctx.revert();

  }, []);

  return (
    <div ref={container} className={styles.help}>
      <div className={styles.title_container}>
        <div className={styles.title}>
          <h4 ref={titleRef} >{title}</h4>
        </div>
      </div>
      <div className={styles.paragraph_container}>
        <div className={styles.paragraph}>
          <p ref={paragraphRef}>{paragrah}</p>
        </div>
      </div>
      <div className={styles.infos}>
        <ul ref={chipsRef} className={styles.chips}>
            {chips.map((chip, index) => {
              return <li key={index} className={styles.chip}>{chip}</li>
            })}
        </ul>
        {/* <a href={"mailto:" + process.env.EMAIL} ref={contact} className={styles.contact_button}>
          Contact me
        </a> */}
      </div>
    </div>
  )
}

export default Help;