'use client'
import React from 'react';
// import './App.css';
import styles from './App.module.css'
import Hello from './components/Section/Hello/Hello'
import AboutMe from './components/Section/AboutMe/AboutMe'
import Projects from './components/Section/Projects/Projects'
import HelpYou from './components/Section/HelpYou/HelpYou'
import Header from './components/Header/Header'
import Contact from './components/Section/Contact/Contact'
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AnimatedCursor from "react-animated-cursor";
import Cursor from './components/Cursor/Cursor';
import Estimate from './components/Section/Estimate/Estimate';

function App() {
  gsap.registerPlugin(ScrollTrigger);

  return (
    <div className={styles.App && styles.grain}>
      <Cursor></Cursor>
      <Header></Header>
      <main className={styles.main}>
        <Hello></Hello>
        <AboutMe></AboutMe>
        <Projects></Projects>
        <HelpYou></HelpYou>
        <Contact></Contact>
    </main>
    </div>
  );
}

export default App;
