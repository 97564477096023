import React from 'react';
import styles from './AboutMe.module.css';
import { gsap } from 'gsap';
import { useRef, useEffect } from 'react';


const AboutMe: React.FC = () => {

  const title = useRef(null);
  const paragraph = useRef(null);
  const container = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from(
        title.current,
        {
          opacity: 0.5,
          duration: 1.5,
          y: "130%",
          ease: "power4.out",
          delay: 0.2,
          skewY: 5,
          stagger: {
            amount: 0.3
          },
          scrollTrigger: {
            trigger: title.current,
            start: 'top bottom',
            end: 'top bottom',   
          }, 
        }
      );

      gsap.from(
        paragraph.current,
        {
          opacity: 0,
          duration: 1.3,
          y: "30%",
          ease: "power4.out",
          delay: .4,
          skewY: .8,
          stagger: {
            amount: 0.3
          },
          scrollTrigger: {
            trigger: paragraph.current,
            end: 'top bottom',          
            start: 'top bottom',
          }, 
        }
      );
    }, container);

    return () => ctx.revert();
  }, []);


  return (
    <div id='aboutme' ref={container} className={styles.aboutme}>
      <div className={styles.title}>
        <h3 ref={title}>About me</h3>
      </div>
      <div className={styles.paragraph}>
        <p ref={paragraph}>Welcome to my personal website! I'm Gauthier Nelkin, originally from the beautiful city of Toulouse in France. My passion for technology has driven me over the last four years to hone my skills as a full-stack developer. With my wide range of skills and my constant motivation to learn, I'm able to adapt to any project.</p>
      </div>
    </div>
  )
}

export default AboutMe;